import { fs } from "../firebase";

export const getUserInformation = userID =>
  fs
    .collection("users")
    .doc(userID)
    .get()
    .then(function(doc) {
      if (doc) {
        return doc;
      } else {
        console.log("No such document!");
      }
    });

export const checkIfUserExists = userID =>
  fs
    .collection("users")
    .doc(userID)
    .get()
    .then(function(doc) {
      if (doc.exists) {
        return doc;
      } else {
        doCreateNewUser(userID).then(function(doc) {
          console.log("do this thing");
          return doc;
        });
      }
    });

export const doCreateNewUser = userID =>
  fs
    .collection("users")
    .doc(userID)
    .set({
      collectedKeys: 0
    })
    .then(function() {
      console.log("Document successfully written!");
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
    });

export const userAddKey = (userID, numberOfKeys) =>
  fs
    .collection("users")
    .doc(userID)
    .set({
      collectedKeys: numberOfKeys
    })
    .then(function() {
      console.log("Document successfully written!");
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
    });
