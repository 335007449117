import cardRecall from "../assests/images/card-recall.png";
import jigsaw from "../assests/images/jigsaw.png";
import partypop from "../assests/images/party-Pop4.jpg";
import rtr from "../assests/images/road-trip-reunion2.png";
import sc from "../assests/images/shuffle-cards.png";
import ws from "../assests/images/word-scramble.png";
import tc from "../assests/images/trivia-casino-small.png";

export const GAMES = {
  MemoryRecall: {
    title: "Card Recall",
    description:
      "How much can you remember? Challenge your short-term memory to master the patterns.",
    imageSrc: cardRecall,
    imageAlt: "Card Recall Game",
    linkDestination: "/memory-recall/",
    gameCategories: {
      screenReaderSupport: true,
      keyBoardOnlyPlay: true,
      difficultySelection: true,
      verticalOnlyMobile: true
    }
  },
  WordScramble: {
    title: "Word Scramble",
    description:
      "How quickly can you untangle scrambled words? Try to set a new personal best.",
    imageSrc: ws,
    imageAlt: "Word Scramble Game",
    linkDestination: "/word-scramble",
    gameCategories: {
      screenReaderSupport: false,
      keyBoardOnlyPlay: true,
      difficultySelection: true,
      verticalOnlyMobile: false
    }
  },
  ShuffleClub: {
    title: "Shuffle Club",
    description:
      "Do you have the reflexes it takes to break all the pucks? Test your limits using your keyboard or mouse.",
    imageSrc: sc,
    imageAlt: "Shuffle Club Game",
    linkDestination: "/shuffle-club",
    gameCategories: {
      screenReaderSupport: false,
      keyBoardOnlyPlay: true,
      difficultySelection: false,
      verticalOnlyMobile: true
    }
  },
  JigsawPuzzle: {
    title: "Jigsaw Puzzle",
    description:
      "Do you find jigsaw puzzles relaxing? The more you finish, the more options you'll unlock.",
    imageSrc: jigsaw,
    imageAlt: "Jigsaw Puzzle Game",
    linkDestination: "/jigsaw",
    gameCategories: {
      screenReaderSupport: false,
      keyBoardOnlyPlay: false,
      difficultySelection: true,
      verticalOnlyMobile: false
    }
  },
  PartyPop: {
    title: "Party Pop",
    description:
      "Can you match the fruit quickly enough to make room for more? ",
    imageSrc: partypop,
    imageAlt: "Party Pop Game",
    linkDestination: "/party-pop",
    gameCategories: {
      screenReaderSupport: false,
      keyBoardOnlyPlay: false,
      difficultySelection: false,
      verticalOnlyMobile: true
    }
  },
  TriviaCasino: {
    title: "Trivia Casino",
    description:
      "How many coins can you earn before you run out of questions? Test your knowledge and let the games begin.",
    imageSrc: tc,
    imageAlt: "Trivia Casino Game",
    linkDestination: "/trivia-casino",
    gameCategories: {
      screenReaderSupport: true,
      keyBoardOnlyPlay: true,
      difficultySelection: true,
      verticalOnlyMobile: true
    }
  }
};
