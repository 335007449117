import React, { Component, Fragment } from "react";
// Data

// Layout

// Components
import GCGameTitle from "./GCGameTitle";
import GCGameDescription from "./GCGameDescription";
import GCGameImage from "./GCGameImage";
import GCGameLink from "./GCGameLink";
import GCIconContainer from "./GCIconContainer";

class GCGameContainer extends Component {
  constructor() {
    super();
    this.state = {
      hidden: false
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (
        (this.props.screenReaderSupport === true &&
          this.props.gameCategories.screenReaderSupport === false) ||
        (this.props.keyBoardOnlyPlay === true &&
          this.props.gameCategories.keyBoardOnlyPlay === false) ||
        (this.props.difficultySelection === true &&
          this.props.gameCategories.difficultySelection === false) ||
        (this.props.verticalOnlyMobile === true &&
          this.props.gameCategories.verticalOnlyMobile === false)
      ) {
        this.setState({ hidden: true });
      } else {
        this.setState({
          hidden: false
        });
      }
    }
  }
  render() {
    return (
      <Fragment>
        {!this.state.hidden ? (
          <li className="gc-game--container" data-wf="Game Preview Block">
            <a
              href={this.props.linkDestination}
              className="gc-game--top"
              tabIndex="-1"
              aria-hidden="true"
            >
              <GCGameImage
                imageSrc={this.props.imageSrc}
                imageAlt={this.props.imageAlt}
              />
            </a>

            <div className="flex-container gc-game--bottom">
              <div className="gc-game--content">
                <GCGameTitle title={this.props.title} />
                <GCGameDescription description={this.props.description} />
              </div>

              <div className="gc-game--actions">
                <GCIconContainer gameCategories={this.props.gameCategories} />
                <GCGameLink
                  linkDestination={this.props.linkDestination}
                  screenReaderLabel={this.props.title}
                  visibleLabel={"Play"}
                />
              </div>
            </div>
          </li>
        ) : null}
      </Fragment>
    );
  }
}

export default GCGameContainer;
