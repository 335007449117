import React, { Component } from "react";
// Data

// Layout

// Components

class GCIconContainer extends Component {
  render() {
    return <div className="gc-icon">{this.props.children}</div>;
  }
}

export default GCIconContainer;
