import React, { Component } from "react";

class GCFeedBackContainer extends Component {
  render() {
    return (
      <article className="gc-feedback--container" data-wf="Game Preview Block">
        <div className="gc-feedback--content">
          <span />
          <span className="gc-feedback--copy">
            <h1>Renew Game Center Survey</h1>
            <p>
              Please complete our brief user survey to let us know which games
              are your favorites and what types you'd like to see more of.
            </p>
          </span>
          <div className="gc-feedback--cta">
            <button
              onClick={e => this.props.toggleQuestionaire(e)}
              className="gc-game--link"
              href="#"
            >
              <span>Take survey</span>
            </button>
          </div>
        </div>
        <div
          className="gc-feedback--background"
          focusable="false"
          aria-hidden="true"
        >
          <div className="background-left" focusable="false" aria-hidden="true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="400.9"
              height="384.1"
              viewBox="0 0 303.9 284.1"
              focusable="false"
              aria-hidden="true"
            >
              <path
                className="st0"
                d="M80.95,284.1V76.47c0-7.94,6.43-14.37,14.37-14.37h149.12c7.94,0,14.37,6.43,14.37,14.37V284.1"
              />
              <line
                className="st1"
                x1="112.36"
                y1="110.77"
                x2="227.4"
                y2="110.77"
              />
              <line
                className="st1"
                x1="112.36"
                y1="135.43"
                x2="227.4"
                y2="135.43"
              />
              <line
                className="st1"
                x1="112.36"
                y1="160.1"
                x2="227.4"
                y2="160.1"
              />
              <line
                className="st1"
                x1="112.36"
                y1="184.77"
                x2="227.4"
                y2="184.77"
              />
              <line
                className="st1"
                x1="112.36"
                y1="209.43"
                x2="227.4"
                y2="209.43"
              />
              <line
                className="st1"
                x1="112.36"
                y1="234.1"
                x2="227.4"
                y2="234.1"
              />
              <line
                className="st2"
                x1="185.36"
                y1="237.77"
                x2="300.4"
                y2="237.77"
              />
              <rect
                x="122.96"
                y="62.1"
                className="st3"
                width="61.71"
                height="154.11"
              />
              <path
                className="st0"
                d="M177.86,222H0V14.37C0,6.43,6.43,0,14.37,0h149.12c7.94,0,14.37,6.43,14.37,14.37V222z"
              />
              <line
                className="st1"
                x1="31.41"
                y1="48.66"
                x2="146.46"
                y2="48.66"
              />
              <line
                className="st1"
                x1="31.41"
                y1="73.33"
                x2="146.46"
                y2="73.33"
              />
              <line className="st1" x1="31.41" y1="98" x2="146.46" y2="98" />
              <line
                className="st1"
                x1="31.41"
                y1="122.66"
                x2="146.46"
                y2="122.66"
              />
              <line
                className="st1"
                x1="31.41"
                y1="147.33"
                x2="146.46"
                y2="147.33"
              />
              <line className="st1" x1="31.41" y1="172" x2="146.46" y2="172" />
              <line
                className="st1"
                x1="31.41"
                y1="196.66"
                x2="146.46"
                y2="196.66"
              />
            </svg>
          </div>
          <div
            className="background-right"
            focusable="false"
            aria-hidden="true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="400"
              height="380"
              viewBox="0 0 255.01 222"
              focusable="false"
              aria-hidden="true"
            >
              <path
                className="st0"
                d="M255.01,222H77.15V14.37C77.15,6.43,83.58,0,91.52,0h149.12c7.94,0,14.37,6.43,14.37,14.37V222z"
              />
              <line
                className="st1"
                x1="108.55"
                y1="48.66"
                x2="223.6"
                y2="48.66"
              />
              <line
                className="st1"
                x1="108.55"
                y1="73.33"
                x2="223.6"
                y2="73.33"
              />
              <line className="st1" x1="108.55" y1="98" x2="223.6" y2="98" />
              <line
                className="st1"
                x1="108.55"
                y1="122.66"
                x2="223.6"
                y2="122.66"
              />
              <line
                className="st1"
                x1="108.55"
                y1="147.33"
                x2="223.6"
                y2="147.33"
              />
              <line className="st1" x1="108.55" y1="172" x2="223.6" y2="172" />
              <path
                className="st2"
                d="M104.03,13.62c1.15-0.55,2.45,0.34,2.34,1.61l-0.42,4.87l-0.66,7.73l-6.33-4.37l-6.33-4.37l6.99-3.36	L104.03,13.62z"
              />
              <polygon
                className="st3"
                points="105.12,29.69 105.61,24.03 105.58,24.37 100.67,20.98 95.77,17.59 96.07,17.44 90.95,19.91 75.28,27.43 89.46,37.22 103.64,47.01"
              />
              <path
                className="st4"
                d="M28.69,155.92l74.24-107.53c1.23-1.79,0.78-4.23-1-5.47L79.71,27.59c-1.79-1.23-4.23-0.78-5.47,1L0,136.12"
              />
            </svg>
          </div>
        </div>
      </article>
    );
  }
}

export default GCFeedBackContainer;
