import React, { Component } from "react";
import PropTypes from "prop-types";

// Data

// Layout

// Components

class GCGameTitle extends Component {
  render() {
    return <h2 className="h1 gc-game--contentTitle ">{this.props.title}</h2>;
  }
}

export default GCGameTitle;

GCGameTitle.propTypes = {
  title: PropTypes.string.isRequired
};
