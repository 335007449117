import React, { Component } from "react";
import PropTypes from "prop-types";

// Data

// Layout

// Components

class GCGameImage extends Component {
  render() {
    return (
      <div className="gc-game--image">
        <img src={this.props.imageSrc} alt={this.props.imageAlt} />
      </div>
    );
  }
}

export default GCGameImage;

GCGameImage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
};
