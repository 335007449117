import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import * as ANALYTICS from "./components/03_global/AdobeAnalyticsFunctions";
import { fs } from "./database";
import "./App.css";

import GCLandingPage from "./components/02_templates/GCLandingPage";
import TimeoutModal from "./components/03_global/TimeoutModal";

class App extends Component {
  constructor() {
    super();
    this.state = {
      wireFrameMode: false,
      userID: "",
      userKeys: 0
    };
    this.wireFrameMode = this.wireFrameMode.bind(this);
    this.getParameterByName = this.getParameterByName.bind(this);
    this.functionGetUserInfo = this.functionGetUserInfo.bind(this);
  }

  componentDidMount() {
    ANALYTICS.adobeAnalyticsHomePageLoad("gamecenter", "homepage");
    let userID = this.getParameterByName("PortalsessionID");

    if (userID) {
      this.setState({
        userID: userID
      });

      fs.checkIfUserExists(userID);

      // This settimeout is just a hack -- need to figure out a better way to do this in the future with a real promise
      setTimeout(
        function() {
          this.functionGetUserInfo(userID);
        }.bind(this),
        500
      );

      window.history.replaceState(null, null, window.location.pathname);
      document.cookie =
        "uhcGCUID=" +
        userID +
        ";" +
        "expires=" +
        new Date(new Date().getTime() + 60 * 60 * 1000 * 0.25).toGMTString();

      let currentTime = new Date(
        new Date().getTime() + 60 * 60 * 1000 * 0.25
      ).toGMTString();
      document.cookie =
        "uhcExpireTime=" +
        currentTime +
        ";" +
        "expires=" +
        currentTime +
        ";path=/";
    } else {
      var x = document.cookie.match(
        new RegExp("(^| )" + "uhcGCUID" + "=([^;]+)")
      );
      if (x) {
        fs.getUserInformation(x[2]).then(querySnapshot => {
          const userKeys = querySnapshot.data().collectedKeys;
          this.setState({ userKeys });
        });
        document.cookie =
          "uhcGCUID=" +
          x[2] +
          ";" +
          "expires=" +
          new Date(new Date().getTime() + 60 * 60 * 1000 * 0.25).toGMTString();
        let currentTime = new Date(
          new Date().getTime() + 60 * 60 * 1000 * 0.25
        ).toGMTString();
        document.cookie =
          "uhcExpireTime=" +
          currentTime +
          ";" +
          "expires=" +
          currentTime +
          ";path=/";
      } else {
        if (window.location.href.indexOf("stage") > -1) {
          window.location.replace("https://stage-medicare.uhc.com/member/health-and-wellness.html");
        } else {
          window.location.replace("https://www.medicare.uhc.com/member/health-and-wellness.html");
        }
      }
    }
  }

  functionGetUserInfo(userID) {
    fs.getUserInformation(userID).then(querySnapshot => {
      const userKeys = querySnapshot.data().collectedKeys;
      this.setState({ userKeys });
    });
  }

  wireFrameMode() {
    this.setState({
      wireFrameMode: !this.state.wireFrameMode
    });
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  render() {
    return (
      <Router>
        <div className={this.state.wireFrameMode ? "wf-mode" : ""}>
          <TimeoutModal
            stage={
              "https://stage-medicare.uhc.com/member/health-and-wellness.html"
            }
            prod={
              "https://www.medicare.uhc.com/member/health-and-wellness.html"
            }
            warningTimerCount={900}
            exitTimerCount={1800}
            isGameCenter={true}
          />
          <GCLandingPage
            userID={this.state.userID}
            userKeys={this.state.userKeys}
          />
        </div>
      </Router>
    );
  }
}

export default App;
