import React, { Component, Fragment } from "react";
import packageJson from "../../../package.json";

class TimeoutModal extends Component {
  constructor() {
    super();
    this.state = {
      warning: false,
      logout: false,
      counter: 0,
      fancyTime: "",
      redirectURL: "",
      userCookie: "",
      activityTracker: ""
    };

    this.resetTimer = this.resetTimer.bind(this);
    this.warningFunction = this.warningFunction.bind(this);
    this.exitFunction = this.exitFunction.bind(this);
    this.tick = this.tick.bind(this);
    this.urlSwitch = this.urlSwitch.bind(this);
    this.bindActivityEvents = this.bindActivityEvents.bind(this);
    this.fauxAuth = this.fauxAuth.bind(this);
    this.setCookie = this.setCookie.bind(this);
    // this.handleLeavePage = this.handleLeavePage.bind(this);
  }

  componentDidMount() {
    let timer = setInterval(this.tick, 1000);
    this.setState({
      build: packageJson.build
    });
    this.setState({ timer });
    this.urlSwitch(this.props.stage, this.props.prod);
    this.bindActivityEvents();
  }

  // handleLeavePage() {}

  bindActivityEvents() {
    document.onload = this.resetTimer;
    document.onmousemove = this.resetTimer;
    document.onmousedown = this.resetTimer; // touchscreen presses
    document.ontouchstart = this.resetTimer;
    document.onclick = this.resetTimer; // touchpad clicks
    document.onscroll = this.resetTimer; // scrolling with arrow keys
    document.onkeypress = this.resetTimer;
  }

  urlSwitch(stage, prod) {
    // Not really a switch =)

    if (window.location.href.indexOf("stage") > -1) {
      this.setState({
        redirectURL: stage
      });
    } else {
      this.setState({
        redirectURL: prod
      });
    }
    if (!this.props.isGameCenter) {
      this.fauxAuth();
    }
  }

  componentWillUnmount() {
    // this.clearInterval(this.state.timer);
  }

  tick() {
    this.setState({
      counter: this.state.counter + 1
    });

    this.fancyTimeFormat(this.props.exitTimerCount - this.state.counter);

    let x = document.cookie.match(
      new RegExp("(^| )" + "uhcExpireTime" + "=([^;]+)")
    );

    let currentTime = new Date(
      new Date().getTime() + 60 * 60 * 1000 * 0.5
    ).toGMTString();

    // console.log(x[2]);
    // console.log(currentTime);
    // console.log(Date.parse(x[2]));
    // console.log(Date.parse(currentTime));
    let ActivityTicker;

    if (x) {
      ActivityTicker = -(Date.parse(x[2]) - Date.parse(currentTime));
      this.setState({ activityTracker: ActivityTicker });
    }

    // console.log(ActivityTicker);

    if (ActivityTicker === -0) {
      this.resetTimer();
    }

    // let shouldNavigate = Date.parse(x[2]) < Date.parse(currentTime);
    // console.log(shouldNavigate);
    if (this.state.counter > this.props.warningTimerCount) {
      this.warningFunction();
    }

    if (this.state.counter >= this.props.exitTimerCount) {
      this.exitFunction();
    }
  }

  resetTimer() {
    this.setState({
      counter: 0,
      warning: false,
      logout: false
    });
    this.setCookie();
  }

  setCookie() {
    var x = document.cookie.match(
      new RegExp("(^| )" + "uhcGCUID" + "=([^;]+)")
    );
    let currentTime = new Date(
      new Date().getTime() + 60 * 60 * 1000 * 0.5
    ).toGMTString();

    this.setState({
      endTime: currentTime
    });

    document.cookie =
      "uhcGCUID=" + x[2] + ";" + "expires=" + currentTime + ";path=/";

    document.cookie =
      "uhcExpireTime=" +
      currentTime +
      ";" +
      "expires=" +
      currentTime +
      ";path=/";
  }

  warningFunction() {
    this.setState({
      warning: true
    });
  }

  exitFunction() {
    var x = document.cookie.match(
      new RegExp("(^| )" + "uhcGCUID" + "=([^;]+)")
    );
    if (x) {
      document.cookie =
        "uhcGCUID=" +
        x[2] +
        ";" +
        "expires=" +
        "Thu, 01 Jan 1970 00:00:01 GMT" +
        ";path=/";
    }

    window.location.replace(this.state.redirectURL);
  }

  fancyTimeFormat(time) {
    // Hours, minutes and seconds
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;

    this.setState({
      fancyTime: ret
    });
  }

  fauxAuth() {
    var x = document.cookie.match(
      new RegExp("(^| )" + "uhcGCUID" + "=([^;]+)")
    );
    var y = document.cookie.match(
      new RegExp("(^| )" + "uhcExpireTime" + "=([^;]+)")
    );
    // console.log(x);
    if (!x) {
      // this.exitFunction();
      // window.location.replace(this.state.redirectURL);
      if (window.location.href.indexOf("stage") > -1) {
        window.location.replace(this.props.stage);
      } else {
        window.location.replace(this.props.prod);
      }
    }
    if (x) {
      this.setState({
        userCookie: x[2]
      });
    }

    // this.setCookie();
  }

  render() {
    return (
      <Fragment>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .version {
              background-color: white!important;
              color: black;
              position: fixed;
            }
              .global_timeoutModal--wrapper {
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 9998;
                background-color: rgba(0,0,0,.3)
              }
              
              .global_timeoutModal--inner {
                position: fixed;
                background-color: white;
                z-index: 9999;
                padding: 32px;
                width: 50%;
                left: 0;
                right: 0;
                margin: 10% auto;
              }
              
              @media (max-width: 900px){
                .global_timeoutModal--inner {
                  width: 90%;
                }
              }
              
              .global_timeoutModal--inner * {
                font-family: helvetica,arial,sans-serif;
                color: black;
              }
              
              .global_timeoutModal--wrapper h1 {
                font-size: 40px;
                margin-bottom: 18px;
              }
              
              
              .global_timeoutModal--wrapper h2 {
                font-size: 30px;
                margin-bottom: 18px;
              }
              
              
              .global_timeoutModal--wrapper p {
                font-size: 18px;
                margin-bottom: 18px;
                line-height: 2;
              }
              
              .global_timeoutModal--wrapper strong {
                font-weight: 700;
              }
              .global_timeoutModal--wrapper .global_timeoutModal--cta_container {
                margin: 0 auto;
                text-align: center;
                padding-top: 20px;
                padding-bottom: 20px;
              }
              
              
              .global_timeoutModal--wrapper .global_timeoutModal--cta_container button {
                background-color: #196ecf;
                border: 2px solid #196ecf;
                color: white;
                padding: 20px;
                font-size: 20px;
                border-radius: 8px;
              }    `
          }}
        />
       
        <div className="global_timeoutModal--placeholder">
          {this.state.warning ? (
            <div className="global_timeoutModal--wrapper">
              <div className="global_timeoutModal--inner">
                <div className="global_timeoutModal--contentWrapper">
                  <h1>Session Timeout</h1>
                  <h2>Need More Time?</h2>
                  <p>
                    Your session is about to expire. You will automatically go
                    back to the home page in:
                  </p>
                  <p>{this.state.fancyTime}</p>
                  <p>
                    To continue your current session, click "Stay on this page"
                    below
                  </p>
                </div>
                <div className="global_timeoutModal--cta_container">
                  <button>Stay on this page</button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default TimeoutModal;
