import React, { Component } from "react";
// Data

// Layout

// Components

const logo = require('../../UHC-Renew-Logo.png');

class GCRenewLogo extends Component {
  render() {
    return (
      <a href={this.props.isHomepage ? null : "/"} className="gc-renew-logo">
        <img className="uhc-logo" alt="Renew by United Healthcare Logo" src={logo}/>
      </a>
    );
  }
}

export default GCRenewLogo;
