import React, { Component } from "react";

// Data

// Layout

// Components

class GCContainer extends Component {
  render() {
    return (
      <div className={"gc-main-container " + this.props.mode}>
        {this.props.children}
      </div>
    );
  }
}

export default GCContainer;
