import React, { Component } from "react";

class GCHeader extends Component {
  constructor() {
    super();
    this.state = {
      accessibilityMenu: false
    };
    this.toggleAccessibilityMenu = this.toggleAccessibilityMenu.bind(this);
    this.activateTarget = this.activateTarget.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.activateTarget);
  }

  toggleAccessibilityMenu() {
    this.setState({
      accessibilityMenu: !this.state.accessibilityMenu
    });
  }

  activateTarget(e) {
    if (e.keyCode === 27) {
      if (this.state.accessibilityMenu === true) {
        this.toggleAccessibilityMenu();
      }
    }
  }

  render() {
    return (
      <header className="gc-header--outer" data-wf={"Header Section"}>
        <div className="gc-header--inner">
          <span />
          <nav className="gc-header--nav">
            <span className="gc-header--nav-item">
              <span className="gc-header--nav-item--inner keys">
                <span className="sr-only">
                  You have collected the following number of keys:
                </span>{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.99"
                  height="17.99"
                  viewBox="0 0 17.99 17.99"
                  className="svg-key-icon"
                  focusable="false"
                  aria-hidden="true"
                >
                  <path
                    className="st0"
                    d="M0,16.99c-0.02,0.55,0.45,1.02,1,1l1.74-0.08c0.21-0.01,0.4-0.09,0.55-0.24l0.34-0.34l1.19,0.03l0.59,0.01	C5.74,17.39,6,17.13,5.99,16.8l-0.01-0.59l-0.02-0.81c0-0.15,0.12-0.27,0.27-0.27l0.81,0.02l0.59,0.01	c0.33,0.01,0.59-0.25,0.58-0.58l-0.01-0.59l-0.02-0.91c0-0.15,0.12-0.27,0.27-0.27l0.91,0.02l0.59,0.01	c0.33,0.01,0.59-0.25,0.58-0.58l-0.01-0.59l-0.01-0.27c-0.86-0.3-1.66-0.78-2.35-1.46l-5.97,5.97c-0.19,0.19-0.49,0.19-0.68,0	c-0.19-0.19-0.19-0.49,0-0.68l6.02-6.02c-0.26-0.34-0.47-0.7-0.65-1.07L0.31,14.7"
                  />
                  <path
                    className="st0"
                    d="M8.8,1.58c-1.68,1.68-2.02,4.19-1.02,6.2C7.93,8.07,8.1,8.35,8.3,8.61C8.45,8.81,8.61,9,8.79,9.19	c0,0,0,0,0,0.01c0.43,0.43,0.9,0.76,1.41,1.02c2.01,1,4.52,0.66,6.2-1.02c2.1-2.1,2.1-5.51,0-7.61S10.9-0.53,8.8,1.58z M15.41,4.73	c-0.59,0.59-1.55,0.59-2.15,0c-0.59-0.59-0.59-1.55,0-2.15s1.55-0.59,2.15,0C16,3.17,16,4.13,15.41,4.73z"
                  />
                </svg>
                {this.props.keys}
              </span>
            </span>
            <span className="gc-header--nav-item">
              <button
                className="gc-header--nav-item--inner accessibility"
                onClick={e => this.props.toggleAccessibilityMenu(e)}
                aria-haspopup="true"
                aria-expanded={this.props.accessibilityMenu}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.46"
                  height="16.4"
                  viewBox="0 0 12.46 16.4"
                  className="svg-little-man"
                  focusable="false"
                  aria-hidden="true"
                >
                  <ellipse
                    transform="rotate(-45.001 6.23 1.381)"
                    className="st0"
                    cx="6.23"
                    cy="1.38"
                    rx="1.38"
                    ry="1.38"
                  />
                  <path
                    className="st0"
                    d="M12.46,5.31V3.74H0v1.57l4.69,0.33v4.2c0,0.09-0.02,0.18-0.05,0.26l-2.66,6.25l1.85,0.04l2.2-4.94 c0.04-0.09,0.13-0.15,0.23-0.15l0,0c0.1,0,0.19,0.06,0.23,0.15l2.2,4.94l1.85-0.04l-2.69-6.3C7.79,9.97,7.77,9.88,7.77,9.79V5.64 L12.46,5.31z"
                  />
                </svg>
                Accessibility
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.93"
                  height="8.88"
                  viewBox="0 0 14.93 8.88"
                  className="svg-chevron"
                  focusable="false"
                  aria-hidden="true"
                >
                  <polyline
                    className="st1"
                    points="1,7.88 7.47,1.42 13.93,7.88"
                  />
                </svg>
              </button>

              {this.props.accessibilityMenu ? (
                <div className="gc-accessibility-menu" aria-role="menu">
                  <form>
                    <fieldset>
                      <p>
                        Check below to show only games with the following
                        assistive features:
                      </p>
                      <div className="form-group form-group--check">
                        <label>
                          <input
                            type="checkbox"
                            className="sr-only"
                            onChange={e =>
                              this.props.filterOption(
                                "screenReaderSupport",
                                e.target.checked
                              )
                            }
                          />
                          <span className="faux-check" />
                          <span className="form-group--icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22.95"
                              height="12.95"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 22.95 12.95"
                            >
                              <path
                                className="st0"
                                d="M22.56,5.52C20.92,3.86,16.57,0,11.48,0C6.39,0,2.03,3.86,0.39,5.52c-0.52,0.53-0.52,1.39,0,1.92 c1.64,1.66,5.99,5.52,11.09,5.52c5.09,0,9.45-3.86,11.08-5.52C23.08,6.91,23.08,6.05,22.56,5.52z M16.02,6.48 c0,2.51-2.04,4.55-4.54,4.55c-2.51,0-4.55-2.04-4.55-4.55c0-2.51,2.04-4.54,4.55-4.54C13.98,1.93,16.02,3.97,16.02,6.48z"
                              />
                              <path
                                className="st0"
                                d="M11.48,2.91c-1.97,0-3.57,1.6-3.57,3.57c0,1.97,1.6,3.57,3.57,3.57c1.97,0,3.57-1.6,3.57-3.57 C15.04,4.51,13.44,2.91,11.48,2.91z"
                              />
                            </svg>
                          </span>
                          <span>Screen Reader Support</span>
                        </label>
                      </div>
                      <div className="form-group form-group--check">
                        <label>
                          <input
                            type="checkbox"
                            className="sr-only"
                            onChange={e =>
                              this.props.filterOption(
                                "keyBoardOnlyPlay",
                                e.target.checked
                              )
                            }
                          />
                          <span className="faux-check" />
                          <span className="form-group--icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13.75"
                              height="19.24"
                              viewBox="0 0 13.75 19.24"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path
                                className="st0"
                                d="M5.18,2.69c1.09,0,1.98,0.89,1.98,1.98v1.71h0.2c0.5,0,0.91-0.41,0.91-0.91V0.91C8.26,0.41,7.86,0,7.35,0H2.79 c-0.5,0-0.91,0.41-0.91,0.91v4.56c0,0.5,0.41,0.91,0.91,0.91H3.2V4.66C3.2,3.57,4.09,2.69,5.18,2.69z"
                              />
                              <path
                                className="st0"
                                d="M3.95,12.15V13l-1.86-1.86c-0.24-0.24-0.55-0.36-0.87-0.36s-0.63,0.12-0.87,0.36c-0.48,0.48-0.48,1.25,0,1.73 c1.45,1.55,2.89,3.1,4.34,4.65c0.44,0.45,1.3,1.2,2.59,1.54c0.48,0.13,0.92,0.17,1.28,0.18h0.56c2.55,0,4.62-2.07,4.62-4.62v-1.25 v-1.38v-1.75c0-0.68-0.55-1.22-1.22-1.22c-0.68,0-1.22,0.55-1.22,1.22V9.68c0-0.68-0.55-1.22-1.22-1.22S8.85,9,8.85,9.68V9.02 c0-0.68-0.55-1.22-1.22-1.22c-0.68,0-1.22,0.55-1.22,1.22V6.38H3.95v5.61V12.15z"
                              />
                              <path
                                className="st0"
                                d="M6.4,4.66c0-0.68-0.55-1.22-1.22-1.22c-0.68,0-1.22,0.55-1.22,1.22v1.71H6.4V4.66z"
                              />
                            </svg>
                          </span>
                          <span>Keyboard-only Play</span>
                        </label>
                      </div>
                      <div className="form-group form-group--check">
                        <label>
                          <input
                            type="checkbox"
                            className="sr-only"
                            onChange={e =>
                              this.props.filterOption(
                                "difficultySelection",
                                e.target.checked
                              )
                            }
                          />
                          <span className="faux-check" />
                          <span className="form-group--icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13.76"
                              height="19.01"
                              viewBox="0 0 13.76 19.01"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path
                                className="st0"
                                d="M6.88,0C3.08,0,0,3.08,0,6.88v4.35c0,0.27,0.22,0.49,0.49,0.49h0.95c0.22,0,0.4,0.18,0.4,0.4v1.24 c0,1.72-0.02,4.11,4.57,3.11c0.39-0.07,0.76-0.15,1.11-0.24c0.23-0.06,0.45,0.11,0.45,0.35v1.94c0,0.27,0.22,0.49,0.49,0.49h4.79 c0.27,0,0.49-0.22,0.49-0.49l0.01-6.81V6.88C13.76,3.08,10.68,0,6.88,0z M12.16,7.62c-0.06,0.46-0.45,0.77-0.88,0.77 c-0.12,0.04-0.25,0.07-0.38,0.07c-0.14,0-0.28-0.03-0.42-0.08L3.4,5.7C3.09,5.58,2.85,5.33,2.73,5.02C2.53,4.74,2.5,4.35,2.71,4.04 c0.33-0.5,0.75-0.93,1.22-1.28c1.35-1.05,3.2-1.37,4.86-0.73c1.4,0.54,2.46,1.64,2.98,2.94C12.13,5.79,12.29,6.7,12.16,7.62z"
                              />
                              <path
                                className="st0"
                                d="M11.31,7.31c-0.02,0.13-0.09,0.24-0.2,0.3c-0.06,0.04-0.19,0.09-0.35,0.03L3.68,4.96 C3.52,4.9,3.46,4.78,3.44,4.71C3.4,4.59,3.42,4.46,3.49,4.35C4.28,3.16,5.62,2.48,7,2.48c0.5,0,1.01,0.09,1.5,0.28 C10.39,3.49,11.57,5.4,11.31,7.31z"
                              />
                            </svg>
                          </span>
                          <span>Difficulty Selection</span>
                        </label>
                      </div>
                      <div className="form-group form-group--check">
                        <label>
                          <input
                            type="checkbox"
                            className="sr-only"
                            onChange={e =>
                              this.props.filterOption(
                                "verticalOnlyMobile",
                                e.target.checked
                              )
                            }
                          />
                          <span className="faux-check" />
                          <span className="form-group--icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13.07"
                              height="19.3"
                              viewBox="0 0 13.07 19.3"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path
                                className="st0"
                                d="M9.81,3.22H3.27c-0.65,0-1.18,0.53-1.18,1.18v11.16c0,0.65,0.53,1.18,1.18,1.18h6.54 c0.65,0,1.18-0.53,1.18-1.18V4.4C10.99,3.75,10.46,3.22,9.81,3.22z"
                              />
                              <path
                                className="st0"
                                d="M10.91,0H2.16C0.97,0,0,0.97,0,2.16v14.98c0,1.19,0.97,2.16,2.16,2.16h8.75c1.19,0,2.16-0.97,2.16-2.16V2.16 C13.07,0.97,12.1,0,10.91,0z M5.11,0.95h2.75c0.18,0,0.33,0.15,0.33,0.33S8.04,1.61,7.86,1.61H5.11c-0.18,0-0.33-0.15-0.33-0.33 S4.92,0.95,5.11,0.95z M11.85,15.56c0,1.13-0.92,2.04-2.04,2.04H3.27c-1.13,0-2.04-0.92-2.04-2.04V4.4c0-1.13,0.92-2.04,2.04-2.04 h6.54c1.13,0,2.04,0.92,2.04,2.04V15.56z"
                              />
                            </svg>
                          </span>
                          <span>
                            Vertical-only mobile support (no screen rotation
                            needed)
                          </span>
                        </label>
                      </div>
                    </fieldset>
                  </form>
                </div>
              ) : null}
            </span>
            {/* <span className="gc-header--nav-item">
              <button className="gc-header--nav-item--inner exit">Exit</button>
            </span> */}
          </nav>
        </div>
      </header>
    );
  }
}

export default GCHeader;
