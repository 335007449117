import React, { Component } from "react";

// Data

// Layout

// Components

class GCSection extends Component {
  render() {
    return (
      <section
        className={"section--outer " + this.props.GCSectionMod}
        data-wf={this.props.dataWFContent}
      >
        <div className="section--inner">{this.props.children}</div>
      </section>
    );
  }
}

export default GCSection;
