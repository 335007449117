import React, { Component } from "react";
import PropTypes from "prop-types";

// Data

// Layout

// Components

class GCGameLink extends Component {
  render() {
    return (
      <a className="gc-game--link" href={this.props.linkDestination}>
        <span>{this.props.visibleLabel}</span>
        <span className="sr-only">
          &nbsp;
          {this.props.screenReaderLabel}
        </span>
      </a>
    );
  }
}

export default GCGameLink;

GCGameLink.propTypes = {
  linkDestination: PropTypes.string.isRequired,
  screenReaderLabel: PropTypes.string,
  visibleLabel: PropTypes.string.isRequired
};
