import React, { Component } from "react";
// import { fs } from "../../database";
// Data
import * as ANALYTICS from "../03_global/AdobeAnalyticsFunctions";
import * as GAMES from "../../data/games";
// Layout
import GCContainer from "../00_layout/GCContainer";
import GCFooter from "../00_layout/GCFooter";
import GCHeader from "../00_layout/GCHeader";
import GCMain from "../00_layout/GCMain";
import GCSection from "../00_layout/GCSection";

// Components

import GCSurvey from "../01_components/GCSurvey";
import GCGameGrid from "../01_components/GCGameGrid";
import GCGameContainer from "../01_components/GCGameContainer";
import GCRenewLogo from "../01_components/GCRenewLogo";
import GCFeedBack from "../01_components/GCFeedBack";
import GCTitleContainer from "../00_layout/GCTitleContainer";

class GCLandingPage extends Component {
  constructor() {
    super();
    this.state = {
      userKeys: 6,
      screenReaderSupport: false,
      keyBoardOnlyPlay: false,
      verticalOnlyMobile: false,
      currentLength: 6,
      toggleQuestionaire: false,
      accessibilityMenu: false
    };

    this.filterOption = this.filterOption.bind(this);
    this.getNumberOfFilteredGames = this.getNumberOfFilteredGames.bind(this);
    this.toggleQuestionaire = this.toggleQuestionaire.bind(this);
    this.toggleAccessibilityMenu = this.toggleAccessibilityMenu.bind(this);
    this.closeAccessibilityMenu = this.closeAccessibilityMenu.bind(this);
  }
  componentWillMount() {
    const gamesList = GAMES.GAMES;
    this.setState({
      gamesList
    });
  }

  toggleAccessibilityMenu() {
    ANALYTICS.adobeAnalyticsLink("Accessibility");
    this.setState({
      accessibilityMenu: !this.state.accessibilityMenu
    });
  }

  closeAccessibilityMenu() {
    this.setState({
      accessibilityMenu: false
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // if (
    //   document.cookie.split(";").filter(item => item.includes("uhcGCUID"))
    //     .length
    // ) {
    //   // console.log("The cookie exists, get the keys from the database");
    //   var x = document.cookie.match(
    //     new RegExp("(^| )" + "uhcGCUID" + "=([^;]+)")
    //   );
    //   // This would be the variable for future userID's
    //   // console.log(x[2]);
    //   fs.getUserInformation("unique-uhc--id").then(querySnapshot => {
    //     const userKeys = querySnapshot.data().collectedKeys;
    //     console.log(userKeys);
    //     this.setState({ userKeys });
    //   });
    // } else {
    //   // console.log("The cookie doesn't exist -- create a new user");
    //   document.cookie = "uhcGCUID=010101";
    //   fs.doCreateNewUser("unique-uhc--id");
    // }

    // fs.getUserInformation(this.props.userID).then(querySnapshot => {
    //   const userKeys = querySnapshot.data().collectedKeys;
    //   console.log(userKeys);
    //   this.setState({ userKeys });
    // });

    // document.cookie = "uhcGCUID=" + this.props;

    // window.history.replaceState(null, null, window.location.pathname);
  }

  filterOption(specificState, isFiltered) {
    this.setState(
      {
        [specificState]: isFiltered
      },
      () => this.getNumberOfFilteredGames()
    );
  }

  getNumberOfFilteredGames() {
    setTimeout(e => {
      let elementArray = document.getElementsByClassName("gc-game--container")
        .length;
      this.setState({ currentLength: elementArray });
    }, 1);
  }

  toggleQuestionaire() {
    ANALYTICS.adobeAnalyticsLink("Take Survey");
    this.setState({
      toggleQuestionaire: !this.state.toggleQuestionaire
    });
    this.setState({
      accessibilityMenu: false
    });
  }

  render() {
    const { gamesList } = this.state;
    return (
      <GCContainer mode={"wireframe-mode"}>
        <GCHeader
          keys={this.props.userKeys}
          toggleAccessibilityMenu={this.toggleAccessibilityMenu}
          accessibilityMenu={this.state.accessibilityMenu}
          filterOption={this.filterOption}
          closeAccessibilityMenu={this.closeAccessibilityMenu}
        />
        {!this.state.toggleQuestionaire ? (
          <GCMain>
            <GCSection
              dataWFContent={"Title Section"}
              GCSectionMod={"section--outer-light"}
            >
              <div className="flex-container">
                <GCRenewLogo isHomepage={true} />
                <span className="title-copy--container">
                  <h1>Renew Game Center</h1>
                  <p>
                    Welcome to the Renew by UnitedHealthcare Game Center! Here
                    you will find a wide variety of fun and engaging games to
                    help keep your mind active. Earn keys to unlock new levels
                    and game features. We’ll keep track of your progress so you
                    can keep coming back, again and again.
                  </p>
                </span>
              </div>
            </GCSection>
            <GCSection
              dataWFContent={"Grid Section"}
              GCSectionMod={"section--outer-dark"}
            >
              <GCGameGrid>
                <h1 className="sr-only">
                  There are &nbsp;
                  {this.state.currentLength} &nbsp; games shown
                </h1>
                {Object.keys(gamesList).map(key => (
                  <GCGameContainer
                    key={key}
                    title={gamesList[key].title}
                    description={gamesList[key].description}
                    imageSrc={gamesList[key].imageSrc}
                    imageAlt={gamesList[key].imageAlt}
                    linkDestination={gamesList[key].linkDestination}
                    linkText={gamesList[key].linkText}
                    gameCategories={gamesList[key].gameCategories}
                    screenReaderSupport={this.state.screenReaderSupport}
                    keyBoardOnlyPlay={this.state.keyBoardOnlyPlay}
                    difficultySelection={this.state.difficultySelection}
                    verticalOnlyMobile={this.state.verticalOnlyMobile}
                  />
                ))}
              </GCGameGrid>
            </GCSection>
            <GCSection
              dataWFContent={"Feedback Section"}
              GCSectionMod={"section--outer-dark"}
            >
              <GCFeedBack
                toggleQuestionaire={this.toggleQuestionaire}
                closeAccessibilityMenu={this.closeAccessibilityMenu}
              />
            </GCSection>
          </GCMain>
        ) : (
          <GCMain>
            <GCSection
              dataWFContent={"Title Section"}
              GCSectionMod={"section--outer-light"}
            >
              <div className="flex-container">
                <GCRenewLogo />
                <GCTitleContainer headerFocus={true} />
              </div>
            </GCSection>
            <GCSection
              dataWFContent={"Game Section"}
              GCSectionMod={"section--outer-dark"}
            >
              <GCSurvey
                gamesList={gamesList}
                toggleQuestionaire={this.toggleQuestionaire}
              />
            </GCSection>
          </GCMain>
        )}

        <GCFooter questionaire={this.state.toggleQuestionaire} />
      </GCContainer>
    );
  }
}

export default GCLandingPage;
