export function adobeAnalyticsHomePageLoad(
  gameName,
  pageName = "",
  surveyAnswer = false
) {
  let pageLoadEvent = document.createEvent("customEvent");
  pageLoadEvent.initCustomEvent("pageLoad", true, true, {
    pageName: "uhc:mr:wellness:rgc:" + pageName + "",
    siteSectionL1: "wellness",
    siteSectionL2: "brain-games",
    siteSectionL3: "" + gameName + "",
    website: "mr",
    businessUnit: "uhc",
    surveyAnswer: "" + surveyAnswer + "",
    loginStatus: "loggedin",
    error: ""
  });
  //console.log(pageName + "firing");
  document.body.dispatchEvent(pageLoadEvent);
}

export function adobeAnalyticsPageLoad(
  gameName,
  pageName = "",
  surveyAnswer = false
) {
  let pageLoadEvent = document.createEvent("customEvent");
  pageLoadEvent.initCustomEvent("pageLoad", true, true, {
    pageName: "uhc:mr:wellness:rgc:" + gameName + ":" + pageName + "",
    siteSectionL1: "wellness",
    siteSectionL2: "brain-games",
    siteSectionL3: "" + gameName + "",
    website: "mr",
    businessUnit: "uhc",
    surveyAnswer: "" + surveyAnswer + "",
    loginStatus: "loggedin",
    error: ""
  });
  //console.log(pageName + "firing");
  document.body.dispatchEvent(pageLoadEvent);
}

export function adobeAnalyticsGameStart(gameName, gameModeDifficultyLevel) {
  let startGameEvent = document.createEvent("customEvent");
  startGameEvent.initCustomEvent("microTaskStart", true, true, {
    microtask: "brain games:" + gameName + ":" + gameModeDifficultyLevel + ""
  });
  document.body.dispatchEvent(startGameEvent);
}

export function adobeAnalyticsGameComplete(
  gameName,
  gameModeDifficultyLevel,
  score = null,
  gameDuration
) {
  let completeGameEvent = document.createEvent("customEvent");
  completeGameEvent.initCustomEvent("microTaskComplete", true, true, {
    microTask: "brain games:" + gameName + ":" + gameModeDifficultyLevel + "",
    gameKPI: "" + score + "", // eg: score:100, level:8, or any other type of KPIs.
    gameDuration: "" + gameDuration + "" // eg: 1m 20s, 0m 50s
  });
  document.body.dispatchEvent(completeGameEvent);
}

export function adobeAnalyticsLink(linkName) {
  let gameLinkEvent = document.createEvent("customEvent");
  gameLinkEvent.initCustomEvent("linkClick", true, true, {
    linkName: "" + linkName + ""
  });
  document.body.dispatchEvent(gameLinkEvent);
}

export function adobeAnalyticsAbandon(
  gameName,
  gameModeDifficultyLevel,
  gameDuration
) {
  let abandon = document.createEvent("customEvent");
  abandon.initCustomEvent("gameAbandonment", true, true, {
    microtask: "brain games:" + gameName + ":" + gameModeDifficultyLevel + "",
    gameDuration: "" + gameDuration + ""
  });
  document.body.dispatchEvent(abandon);
}

export function adobeAnalyticsExternalLinkTracking(externalLinkName) {
  let gameExternalLink = document.createEvent("customEvent");
  gameExternalLink.initCustomEvent("exitLink", true, true, {
    linkName: "" + externalLinkName + "" //set to the name of the button or link clicked, ex- 'renew positivity'
  });
  document.body.dispatchEvent(gameExternalLink);
}

export function adobeAnalyticsSurveyStart() {
  let startSurveyEvent = document.createEvent("customEvent");
  startSurveyEvent.initCustomEvent("microTaskStart", true, true, {
    microtask: "brain games:survey:start"
  });
  document.body.dispatchEvent(startSurveyEvent);
}

export function adobeAnalyticsSurveyComplete(
  q1, a1, q2, a2, q3, a3, q4, a4
) {
  let completeSurveyEvent = document.createEvent("customEvent");
  completeSurveyEvent.initCustomEvent("microTaskComplete", true, true, {
    microTask: "brain games:survey:start",
    surveyResponses: q1 +':'+ a1 +'|'+ q2 +':'+ a2 +'|'+ q3 +':'+ a3 +'|'+ q4 +':'+ a4
  });
  console.log("SurveyComplete firing:" + q1 +':'+ a1 +'|'+ q2 +':'+ a2 +'|'+ q3 +':'+ a3 +'|'+ q4 +':'+ a4);
  document.body.dispatchEvent(completeSurveyEvent);
}
