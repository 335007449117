import React, { Component } from "react";
import * as ANALYTICS from "../03_global/AdobeAnalyticsFunctions";
class GCSurvey extends Component {
  constructor() {
    super();

    this.state = {
      success: true,
      newPuzzles: false,
      newWordGames: false,
      newBoardGames: false,
      newCardGames: false,
      newTriviaGames: false,
      favoriteGame: false,
      howSatisfied: false,
      howManyGames: false
    };
    this.showSuccess = this.showSuccess.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    ANALYTICS.adobeAnalyticsPageLoad("survey", "start");
    ANALYTICS.adobeAnalyticsSurveyStart();
  }

  onSubmit(event) {
    event.preventDefault();
  }

  showSuccess(e) {
    e.preventDefault();
    this.setState({
      success: false
    });
  }

  handleChange = event => {
    const { name, type, value, checked } = event.target;
    console.log(name + " " + type + " " + value);
    const val = type === "number" ? parseFloat(value) : value;
    this.setState({
      [name]: val
    });

    if (type === "checkbox") {
      this.setState({
        [name]: checked
      });
    }
  };

  getTypesOfNewGames() {
    let gamesArray = [];
    if (this.state.newWordGames) gamesArray.push('Word Games');
    if (this.state.newPuzzles) gamesArray.push('Puzzles');
    if (this.state.newBoardGames) gamesArray.push('Board Games');
    if (this.state.newCardGames) gamesArray.push('Card Games');
    if (this.state.newTriviaGames) gamesArray.push('Trivia Games');
    return gamesArray.join(',');
  }

  render() {
    return (
      <div>
        {this.state.success ? (
          <form data-wf="Game Preview Block" onSubmit={e => this.onSubmit(e)}>
            {this.state.success}
            <div className="gc-survey--container">
              <div className="form-group">
                <label htmlFor="question1">
                  What is your favorite game in the Game Center?
                </label>
                <select
                  className="form-input"
                  id="question1"
                  name="favoriteGame"
                  required
                  onChange={this.handleChange}
                >
                  <option value="">Select One...</option>
                  {Object.keys(this.props.gamesList).map(key => (
                    <option value={this.props.gamesList[key].title}>
                      {this.props.gamesList[key].title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>
                  What type of new games would you like to see added to the Game
                  Center?
                </label>

                <div className="form-group form-group--check">
                  <label>
                    <input
                      type="checkbox"
                      className="sr-only"
                      id="newWordGames"
                      name="newWordGames"
                      value={this.state.newWordGames}
                      checked={this.state.newWordGames}
                      onChange={this.handleChange}
                    />
                    <span className="faux-check" />
                    <span>Word games (e.g. crosswords )</span>
                  </label>
                </div>
                <div className="form-group form-group--check">
                  <label>
                    <input
                      type="checkbox"
                      className="sr-only"
                      id="newPuzzles"
                      name="newPuzzles"
                      value={this.state.newPuzzles}
                      checked={this.state.newPuzzles}
                      onChange={this.handleChange}
                    />
                    <span className="faux-check" />
                    <span>Puzzles ( e.g. sodoku, cryptograms )</span>
                  </label>
                </div>
                <div className="form-group form-group--check">
                  <label>
                    <input
                      type="checkbox"
                      className="sr-only"
                      id="newBoardGames"
                      name="newBoardGames"
                      value={this.state.newBoardGames}
                      checked={this.state.newBoardGames}
                      onChange={this.handleChange}
                    />
                    <span className="faux-check" />
                    <span>Board games</span>
                  </label>
                </div>
                <div className="form-group form-group--check">
                  <label>
                    <input
                      type="checkbox"
                      className="sr-only"
                      id="newCardGames"
                      name="newCardGames"
                      value={this.state.newCardGames}
                      checked={this.state.newCardGames}
                      onChange={this.handleChange}
                    />
                    <span className="faux-check" />
                    <span>Card games</span>
                  </label>
                </div>
                <div className="form-group form-group--check">
                  <label>
                    <input
                      type="checkbox"
                      className="sr-only"
                      id="newTriviaGames"
                      name="newTriviaGames"
                      onChange={this.handleChange}
                    />
                    <span className="faux-check" />
                    <span>Trivia games</span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label htmlForm="za">
                  How many games in the Game Center have you tried?
                </label>
                <select
                  id="za"
                  className="form-input"
                  required
                  id="howManyGames"
                  name="howManyGames"
                  onChange={this.handleChange}
                >
                  <option value="">Select One...</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </div>
              <div className="form-group">
                <label>How satisfied are you with the Game Center?</label>
                <select
                  className="form-input"
                  required
                  id="satisfied"
                  name="howSatisfied"
                  onChange={this.handleChange}
                >
                  <option value="">Select One...</option>
                  <option value="VerySatisfied">Very Satisfied</option>
                  <option value="Satisfied">Satisfied</option>
                  <option value="Neutral">Neutral</option>
                  <option value="Dissatisfied">Dissatisfied</option>
                  <option value="VeryDissatisfied">Very Dissatisified</option>
                  {/* <option>5</option>
                  <option>6</option> */}
                </select>
              </div>
            </div>
            <div>
              <button
                onClick={e => this.showSuccess(e)}
                className="gc-game--link gc-game--link__full"
              >
                <span>Submit</span>
              </button>
            </div>
          </form>
        ) : (
          <div>
            {/* <h1>Thank you for your time</h1> */}
            {/*ANALYTICS.adobeAnalyticsPageLoad(
              "survey questions",
              "end",
              "FavoriteGame:(" +
                this.state.favoriteGame +
                "),TypesOfNewGames:WordGames(" +
                this.state.newWordGames +
                ")Puzzles(" +
                this.state.newPuzzles +
                ")BoardGames(" +
                this.state.newBoardGames +
                ")CardGames(" +
                this.state.newCardGames +
                ")TriviaGames(" +
                this.state.newTriviaGames +
                "),HowManyGames:(" +
                this.state.howManyGames +
                "),HowSatisfied:(" +
                this.state.howSatisfied +
                ")"
            )*/}
            {ANALYTICS.adobeAnalyticsPageLoad("survey", "complete")}
            {ANALYTICS.adobeAnalyticsSurveyComplete(
              "FavoriteGame", this.state.favoriteGame,
              "TypesOfNewGames", this.getTypesOfNewGames(),
              "HowManyGames", this.state.howManyGames,
              "HowSatisfied", this.state.howSatisfied
            )}
            <h2 className="h2">
              Thank you! Your survey has been received. Please click CONTINUE to
              return to the Game Center.
            </h2>
            <div>
              <button
                onClick={e => this.props.toggleQuestionaire(e)}
                className="gc-game--link gc-game--link__full"
              >
                <span>Continue</span>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default GCSurvey;
