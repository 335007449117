import React, { Component } from "react";

// Data

// Layout

// Components

class GCMain extends Component {
  render() {
    return <main>{this.props.children}</main>;
  }
}

export default GCMain;
