import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyB1Yu2L0viF85Gm4F5_W81u6CHwPE1yPo4",
  authDomain: "uhc-game-center-dev.firebaseapp.com",
  databaseURL: "https://uhc-game-center-dev.firebaseio.com",
  projectId: "uhc-game-center-dev",
  storageBucket: "uhc-game-center-dev.appspot.com",
  messagingSenderId: "188149433064"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const fs = firebase.firestore();
const auth = firebase.auth();

//const settings = { timestampsInSnapshots: true };
//fs.settings(settings);

export { db, fs, auth };
