import React, { Component } from "react";

class GCTitleContainer extends Component {
  // constructor() {
  //   super();
  // }

  componentDidMount() {
    if (this.props.headerFocus) {
      this.headerText.focus();
    }

    window.scrollTo(0, 0);
  }
  render() {
    return (
      <span className="title-copy--container">
        <h1
          ref={input => {
            this.headerText = input;
          }}
          tabIndex={-1}
        >
          Survey Questions
        </h1>
      </span>
    );
  }
}

export default GCTitleContainer;
