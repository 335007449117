import React, { Component } from "react";
// Data

// Layout

// Components

class GCGameGrid extends Component {
  render() {
    return (
      <div className="gc-game--grid" aria-live="polite">
        <ul className="game-grid--inner">{this.props.children}</ul>
      </div>
    );
  }
}

export default GCGameGrid;
