import React, { Component } from "react";

// Data

// Layout

// Components

class GCGameDescription extends Component {
  render() {
    return <p>{this.props.description}</p>;
  }
}

export default GCGameDescription;
